.user-editor-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin: 20px;
}

.user-editor {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .user-editor {
    width: 90%;
  }
}
