.visits-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-x: scroll;
    justify-content: center;
    /* height: 85vh; */
}

/*
.visits-drawer {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */

.visits-bottom-card {
    flex-grow: 1;
    width: 100%;
    height: 85vh;
    overflow-y: scroll;
}

.visits-list-container {
    margin: 10px;
    overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
    .visits-bottom-card {
        margin: 10px;
    }
}
