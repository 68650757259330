.logo {
  height: 32px;
  margin: 16px;
}

.user-layout .user-layout-background {
  background: #fff !important;
  height: 100vh;
}

.edit-profile {
  width: 100%;
  text-align: center;
}

.edit-profile-card {
  width: 50% !important;
  text-align: center;
}
