.sessions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  width: 100%;
  overflow-x: scroll;
}

.sessions-drawer {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.sessions-bottom-card {
  flex-grow: 1;
  height: 90vh !important;
  overflow-y: scroll;
}

.sessions-list-container {
  margin: 10px;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  .sessions-bottom-card {
    margin: 10px;
  }
}
