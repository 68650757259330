.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

body {
  height: 100vh;
  position: relative;
}

.ocrloader {
  display: block;
  width: 125px;
  height: 125px;
  position: absolute;
  top: 60px;
  margin-left: 57.5px;
  backface-visibility: hidden;
  z-index: 2;
}

.ocrloader span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3px;
  background-color: #ff000099;
  z-index: 1 !important;
  transform: translateY(135%);
  animation: move 1.5s cubic-bezier(0.15, 0.44, 0.76, 0.64);
  animation-iteration-count: infinite;
}

.ocrloader:before,
.ocrloader:after,
.ocrloader em:after,
.ocrloader em:before {
  border-color: #000;
  content: "";
  position: absolute;
  width: 19px;
  height: 16px;
  border-style: solid;
  border-width: 0;
}

.ocrloader:before {
  left: 0;
  top: 0;
  border-left-width: 1px;
  border-top-width: 1px;
}

.ocrloader:after {
  right: 0;
  top: 0;
  border-right-width: 1px;
  border-top-width: 1px;
}

.ocrloader em:before {
  left: 0;
  bottom: 0;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.ocrloader em:after {
  right: 0;
  bottom: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(4150%);
  }
  50% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(1041%);
  }
  75% {
    transform: translateY(3125%);
  }
}
